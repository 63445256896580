import React from 'react'
import Navbar from '../../components/user/Navbar'
import Footer from '../../components/user/Footer'
import OurPriceComponent from '../../components/user/price/OurPriceComponent'
import PriceComponent from '../../components/user/PriceComponent'
import PriceRoutinePageComponent from '../../components/user/price/PriceRoutinePageComponent'

const RoutineServicesPage = ({type}) => {
  return (
    <div>
       <div className="nav-fix"><Navbar /></div>
{/* <PriceComponent/> */}
{/* <OurPriceComponent type="Routine"/> */}

<PriceRoutinePageComponent type={type} h1={true}/>
        <Footer/>
    </div>
  )
}

export default RoutineServicesPage