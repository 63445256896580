import React from "react";
import { Link } from "react-router-dom";
import google from "../../images/icon/google-icon.png";

const  LandingTestimonial = () => (
  <div>
    <div className="home-container-6">
      <div className="home-container-6-heading">Customers <span>Speaks</span></div>
     
    
      <div className="home-container-6-content">
        <div className="home-container-6-content-cols">
          <div className="home-container-6-content-col-1">
            <div className="home-container-6-content-cos-1-google">
              <img title="Garage On Call - Best Bike Service Google Reviews" src={google} alt="google-icon" />
            </div>
            <div className="home-container-6-content-cos-1-stars">
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
            </div>
            <div className="home-container-6-content-cos-1-text">
              

              I had a fantastic experience at GarageOnCall! The staff were
              knowledgeable and friendly, providing top-notch service and useful
              maintenance tips. They completed the 
        
              
            </div>
            <div className="home-container-6-content-cos-1-name">
              Aviral Singh
            </div>
            <div className="home-container-6-content-cos-1-date">
              a month ago
            </div>
            <div className="home-container-6-content-cos-1-img">
              <img title="Garage On Call happy client"
                src="https://lh3.googleusercontent.com/a-/ALV-UjVnNHWO_M65rRgLeD7aEyFn7EHzR_RgDbXkwaIn1KCN6GW9ma4D=w60-h60-p-rp-mo-br100"
                alt="Bike service  at doorstep" />
            </div>
          </div>

          <div className="home-container-6-content-col-1">
            <div className="home-container-6-content-cos-1-google">
              <img title="Garage On Call - Best Bike Service Google Reviews" src={google} alt="google-icon" />
            </div>
            <div className="home-container-6-content-cos-1-stars">
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
            </div>
            <div className="home-container-6-content-cos-1-text">
              Found these guys through google and was not very convinced initially
              of the services but Mr. Ankit provided video evidence that they
              can service high capacity 
            </div>
            <div className="home-container-6-content-cos-1-name">
              Shivanshu Aggarwal
            </div>
            <div className="home-container-6-content-cos-1-date">
              a month ago
            </div>
            <div className="home-container-6-content-cos-1-img">
              <img title="Doorstep bike service trusted client"
                src="https://lh3.googleusercontent.com/a-/ALV-UjW-RAk6e1fTHY5U-kRgQhUDRPf10Fi1NOOn5_m2IlCHg3TDy0xCtQ=w60-h60-p-rp-mo-ba4-br100"
                alt="best Bike service" />
            </div>
          </div>

          <div className="home-container-6-content-col-1">
            <div className="home-container-6-content-cos-1-google">
              <img title="Garage On Call - Best Bike Service Google Reviews" src={google} alt="google-icon" />
            </div>
            <div className="home-container-6-content-cos-1-stars">
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
            </div>
            <div className="home-container-6-content-cos-1-text">


              Great services when you are stuck somewhere and also when you don't
              want to go out of the comfort of your house. "Garage on call"
              offers great range of services.Most 

            </div>
            <div className="home-container-6-content-cos-1-name">
            gaurav kumar
            </div>
            <div className="home-container-6-content-cos-1-date">
              a month ago
            </div>
            <div className="home-container-6-content-cos-1-img">
              <img title="Garage On Call trusted client"
                src="https://lh3.googleusercontent.com/a-/ALV-UjVjINh33LXBrzXRPW8pQbaoSyV4H__WWC4nfsAUmIg_l4B9V1wX=w60-h60-p-rp-mo-br100"
                alt="doorstep Bike service " />
            </div>
          </div>

          <div className="home-container-6-content-col-1">
            <div className="home-container-6-content-cos-1-google">
              <img title="Garage On Call - Best Bike Service Google Reviews" src={google} alt="google-icon" />
            </div>
            <div className="home-container-6-content-cos-1-stars">
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
            </div>
            <div className="home-container-6-content-cos-1-text">


              My bile was breakdown and have called GOC, GOC(Garage on Call) 
              acted immediately and assign Mr. Arun Kumar. On very next 
              day early morning Me Arun visited
        
            </div>
            <div className="home-container-6-content-cos-1-name">
            Deep Kamal
            </div>
            <div className="home-container-6-content-cos-1-date">
              a month ago
            </div>
            <div className="home-container-6-content-cos-1-img">
              <img title="At home bike repair happy client"
                src="https://lh3.googleusercontent.com/a-/ALV-UjX4IHryHwM6Fu1VtiXAm86IeIbZrpXpfGZ4dMgHE_4Xo5rwGvhZ=w60-h60-p-rp-mo-ba2-br100"
                alt="Bike service  at home" />
            </div>
          </div>

          <div className="home-container-6-content-col-1">
            <div className="home-container-6-content-cos-1-google">
              <img title="Garage On Call - Best Bike Service Google Reviews" src={google} alt="google-icon" />
            </div>
            <div className="home-container-6-content-cos-1-stars">
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
            </div>
            <div className="home-container-6-content-cos-1-text">
             
              The service was excellent for my Avenger 220  street bike. 
              Arun was very diligent in making me aware of the problems with 
              the bike and that was the best part of the whole
            
            </div>
            <div className="home-container-6-content-cos-1-name">
            Yashesh Rao
            </div>
            <div className="home-container-6-content-cos-1-date">
              a week ago
            </div>
            <div className="home-container-6-content-cos-1-img">
              <img title="Garage On Call trusted client"
                src="https://lh3.googleusercontent.com/a-/ALV-UjUuhar4Kj54m2NhIXD2LVpv1we1TSwHs0Hm3dICusX4KQCxwo3I=w60-h60-p-rp-mo-br100"
                alt="Bike service near me" />
            </div>
          </div>
        </div>
      </div>

      <div className="home-container-6-head">
        <div className="home-container-6-head-col-1">
          <div className="home-container-6-head-col-1-heading-1">
            Customer testimonial on Google
          </div>
          <div className="home-container-6-head-col-1-heading-2">
            <i className="fa-solid fa-star"></i>
            <i className="fa-solid fa-star"></i>
            <i className="fa-solid fa-star"></i>
            <i className="fa-solid fa-star"></i>
            <i className="fa-solid fa-star"></i>
            &nbsp;4.7 Rating on Google
          </div>
        </div>
     
     
      </div>

      
      <div className="landing-page-button">

<a href="#book-form">Book Now</a>

</div>
      
      
    </div>
  </div>
);

export default  LandingTestimonial;
