import React, { useContext, useState } from "react";
import CountUp from "react-countup";

import { useNavigate } from "react-router-dom";
import LoadingComponent from "./LoadingComponent";
import AuthContext from "../../context/AuthContext";

export const Header = () => {

  const [loading, setLoading] = useState(false);

  
    // -----------------------------------------------------------------------------------------------------------------------------------------------------------
    // form submit  -----------------------------------------------------------------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  
    const pageFullUrl = window.location.href;
    console.log(pageFullUrl);
  
    // router navigate  ================================================================================================================
    const navigate = useNavigate();
  
    // all useStates  ================================================================================================================
  
    const [error, setError] = useState(null);
  
    const [data, setData] = useState({
      formUrl: pageFullUrl,
      formType: "Home page header",
      phone: "",
    });
  
    const handlePhoneNumber = (e) => {
      const value = e.target.value;
      setError(null)
  
      if (value.length <= 10) {
        setData({
          ...data,
          phone: value,
        });
      }
    };
  
    const { createBooking } = useContext(AuthContext);
  
    const submitForm = async (e) => {
      setLoading(true);
      e.preventDefault();
  
      if (data.phone.length != 10) {
        setError("enter the valid number");
      } else {  
        await createBooking(
          data,
          setError,
          navigate
        );
      }
      setLoading(false);
    };
  
  
  
  
  
  
  
  // -------------------------------------------------------------------------------------------------------------------------------------------------------------------------


  return (
    <div>
      {loading ? (
        <div className="data-loading">
          <LoadingComponent />
        </div>
      ) : (
        ""
      )}

      <div className='header'>

        <div className='header-col-1'>
          <h1 className='header-c-heading-1'>Bike Service & Repair <br />at Your Doorstep</h1>
          <h2 className='header-c-heading-2'>Book Expert Mechanic to Fix <br />your Bike at Home</h2>
          {
            error?
            <div className='header-c-error'>{error}</div>
            :""
          }

          <form className='header-c-form' onSubmit={submitForm}>
            <input type='number' placeholder='Enter your number*'
              required
              onChange={handlePhoneNumber}
              value={data.phone}
            />
            <br />
            <button type="submit"><div className="">BOOK NOW</div></button>
          </form>

          <div className='header-c-inc'>
            <div className='header-c-inc-col'>
            <h3>
              <CountUp start={0}  end={4} duration="2"  enableScrollSpy />.<CountUp start={0} end={7} duration="7"  enableScrollSpy />/ 5
            </h3>

              {/* <span id='counter4'></span><span className=''>.</span><span id='counter7'></span>/ 5 */}
              <h4 className=''>Based on 1,00,000+ <br /> Reviews</h4>

            </div>
            <div className='header-c-inc-col'>
            <h3>
              <CountUp start={0} end={100000} duration="3"  enableScrollSpy /> +
            </h3>
              {/* <span id='counter50000'></span> + */}
              <h4 className=''>Happy <br /> Customers</h4>

            </div>


          </div>

        </div>



        <div className='header-col-2'>
          {/* <img className="head-img-1" src={img1} />
          <img className="head-img-2 imgrot" src={img2} /> */}
          {/* <img className="head-img-3" src={img} /> */}
          {/* <img className="head-img-4" src={img3} /> */}
        </div>



      </div>


    </div>
  )
}
