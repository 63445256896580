import React from 'react'
import brand1 from '../../images/brands/zomato.jpg'
import brand2 from '../../images/brands/allianz.jpg'
import brand3 from '../../images/brands/motul.jpg'
import brand4 from '../../images/brands/wroffy-apnamech.jpg'
import brand5 from '../../images/brands/insurance-dekho.jpg'
import brand6 from '../../images/brands/zepto.png'
import brand7 from '../../images/brands/blinkit.jpg'
import brand8 from '../../images/brands/Dunzo.png'
import brand9 from '../../images/brands/mcdonalds.png'
import brand10 from '../../images/brands/rapido.jpg'
import brand11 from '../../images/brands/Turtlemint.png'
import brand12 from '../../images/brands/Buniyad.png'
import brand13 from '../../images/brands/wurth.png'

const LandingTrustedBrand = () => {
  return (
    <div>
        <div className="trusted-brands">
        <div className="trusted-brands-heading">Trusted by <span>Top Brands</span> & <span>100,000+</span> People Across <span>India</span></div>
      {/* <div className="heading-underline"><hr /></div> */}

        <div className="trusted-brands-content">
            <img src={brand13} alt="brand logo" title='Doorstep Bike Service Trusted Clients' />
            <img src={brand3} alt="brand logo"  title='Doorstep Two Wheeler Service Trusted People' />
            <img src={brand11} alt="brand logo" title='At Home Bike Service Trusted Customers' />
            <img src={brand12} alt="brand logo" title='At Home Two Wheeler Service Trusted Brands' />
            <img src={brand8} alt="brand logo"  title='Doorstep Bike Service Trusted Companies' />
            <img src={brand2} alt="brand logo"  title='Doorstep Two Wheeler Service Trusted Clients' />
            <img src={brand7} alt="brand logo"  title='At Home Bike Service Trusted People' />
            <img src={brand4} alt="brand logo"  title='At Home Two Wheeler Service Trusted Customers' />
            <img src={brand10} alt="brand logo" title='Doorstep Bike Service Trusted Brands' />
            <img src={brand5} alt="brand logo"  title='Doorstep Two Wheeler Service Trusted Companies' />
            <img src={brand1} alt="brand logo"  title='At Home Bike Service Trusted Clients' />
            <img src={brand9} alt="brand logo"  title='Doorstep Two Wheeler Service Trusted Customers' />
            <img src={brand6} alt="brand logo"  title='At Home Bike Service Trusted Brands' />
        </div>

        <div id="faq" className="landing-page-button">

<a href="#book-form">Book Now</a>

</div>

        </div>
    </div>
  )
}

export default LandingTrustedBrand