import React, { useContext, useEffect, useState } from "react";
import img1 from "../../images/bike-repair-at-home.jpg";
import img2 from "../../images/garage-on-call.jpg";
import img3 from "../../images/bike-mechanic-near-me.jpg";
import img5 from "../../images/scooty-service-at-home.jpg";
import { useNavigate } from "react-router-dom";
import CountUp from "react-countup";
import LoadingComponent from "./LoadingComponent";
import AuthContext from "../../context/AuthContext";

const BookingComponent = ({ tag, heading }) => {

  const [loading, setLoading] = useState(false);
 
 
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  // form submit  -----------------------------------------------------------------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------

  const pageFullUrl = window.location.href;
  console.log(pageFullUrl);

  // router navigate  ================================================================================================================
  const navigate = useNavigate();

  // all useStates  ================================================================================================================

  const [error, setError] = useState(null);

  const [data, setData] = useState({
    formUrl: pageFullUrl,
    formType: "Booking Form",
    name: "",
    phone: "",
    bikeBrand: "",
    bikeModel: "",
    bikeCC: "",
    address: "",
    message: "",
  });



  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSelectBrand = (e) => {

    const value = e.target.value;

    setData({
      ...data,
      bikeBrand: value,
      bikeModel: ""
    });

  }

  const handlePhoneNumber = (e) => {
    const value = e.target.value;
    setError(null);

    if (value.length <= 10) {
      setData({
        ...data,
        phone: value,
      });
    }
  };

  const { createBooking, fetchBikes } = useContext(AuthContext);

  const submitForm = async (e) => {
    setLoading(true);
    e.preventDefault();

    if (data.phone.length != 10) {
      setError("enter the valid number");
    } else {

      await createBooking(data, setError, navigate);
    }
    setLoading(false);
  };

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------

  const [bikeData, setBikeData] = useState([]);

  const fetchData = () => fetchBikes(setBikeData);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      {loading ? (
        <div className="data-loading">
          <LoadingComponent />
        </div>
      ) : (
        ""
      )}
      <div className="select-bike">
        <div className="select-bike-content">
          {/* ----col-1----- */}

          <div className="select-bike-col-1  ">
            <div className="sb-img1 zoomIn">
              <img
                className=""
                src={img1}
                title="best bike servicing at home"
                alt="bike-repair-at-home"
              />
            </div>
            <div className="sb-img2 zoomIn">
              <img
                className=""
                src={img2}
                title="bike mechanic online"
                alt="garage-on-call"
              />
            </div>
            <div className="sb-img3 zoomIn">
              <img
                className=""
                src={img3}
                title="two wheeler service at home"
                alt="bike-mechanic-near-me"
              />
            </div>
            <div className="sb-img4 zoomIn">
              <img
                className=""
                src={img5}
                title="bike mechanic near me"
                alt="scooty-service-at-home"
              />
            </div>
          </div>

          {/* ----col-2----- */}

          <div className="select-bike-col-2 shadow-01 w-100">
            <div className="sb-col-1">
              {/* {tag === "h1" ? (
                <h1 className="sb-heading-1">Book Service</h1>
              ) : (
              )} */}
              <h2 className="sb-heading-1">Book Service</h2>
              <h2 className="sb-heading-2 ">
                Welcome to{" "}
                <span>
                  {" "}
                  <i className="fa-solid fa-screwdriver-wrench"></i>
                </span>{" "}
                Garage On Call
              </h2>
              {
                tag === "h1" ?
              <h1 className="sb-heading-3 ">
                Experience the best bike services at doorstep
              </h1>
              :
              <h3 className="sb-heading-3 ">
                Experience the best bike services at doorstep
              </h3>
              }
              <p className="sb-text">
                Get the doorstep bike service at your location by experts.
                <br />
                Our mission to ensure excellent quality bike service & repair
                work at home to everyone at a reasonable price.
              </p>
            </div>

            <div className="sb-col-2">
              <div className="sb-c2-col">
                <div className="sb-c2-text-1">
                  <CountUp start={0} end={4} duration="2" enableScrollSpy />.
                  <CountUp start={0} end={7} duration="7" enableScrollSpy /> / 5{" "}
                </div>
                <div className="sb-c2-text-2">
                  <div className="sb-c2-2">Google Rating</div>
                </div>
              </div>

              <div className="sb-c2-col">
                <div className="sb-c2-text-1">
                  <CountUp
                    start={0}
                    end={100000}
                    duration="3"
                    enableScrollSpy
                  />{" "}
                  +
                </div>
                <div className="sb-c2-text-2">
                  <div className="sb-c2-2">Happy Customers</div>
                </div>
              </div>
            </div>

            <div>
              <form className="sb-col-3 select-bike-form" onSubmit={submitForm}>
                <input
                  required
                  className="select-bike-inp text-center"
                  type="text"
                  placeholder="* Enter your name"
                  name="name"
                  onChange={handleInput}
                />
                <input
                  required
                  className="select-bike-inp text-center"
                  type="number"
                  placeholder="* Enter your phone number"
                  name="phone"
                  onChange={handlePhoneNumber}
                  value={data.phone}
                />

                <div className="sb-c3-1">

                

                  
                  <select
                    required
                    className="select-bike-inp"
                    name="bikeBrand"
                    value={data.bikeBrand}
                    onChange={handleSelectBrand}
                  >
                    <option value="" disabled selected>
                      --- select brand ---
                    </option>

                    {bikeData &&
                      bikeData.map((e) => (
                        <option value={e.brand}>{e.brand}</option>
                      ))}
                    <option value="Other Brand">Other Brand</option>
                  </select>

                  {
                  data.bikeBrand
                  ?
                        bikeData
                          .find((e) => e.brand === data.bikeBrand)
                   ? (
                    <select
                      required
                      className="select-bike-inp"
                      name="bikeModel"
                      value={data.bikeModel}
                      onChange={handleInput}
                    >
                      <option disabled selected value="">
                        --- Select Model ---
                      </option>
                      {bikeData &&
                        bikeData
                          .find((e) => e.brand === data.bikeBrand)
                          .models.map((e) => <option value={e}>{e}</option>)}

                          <option value="Other Model">Other Model</option>
                          
                    </select>
                  ) : (

                    <input
                      required
                      className="select-bike-inp text-center"
                      type="text"
                      placeholder="* Enter bike brand name"
                      name="bikeModel"
                      value={data.bikeModel}
                      onChange={handleInput}
                />
              )
                    :""
                    
                }

                  {/* ============================================================================== */}

                 


                </div>

                <select
                  required
                  className="select-bike-inp text-center"
                  name="bikeCC"
                  onChange={handleInput}
                >
                  <option disabled selected value="">
                    --- select bike CC ---
                  </option>


                      <option value="Below 125 CC - Rs. 299/-">
                        Below 125 CC &nbsp; - &nbsp; Rs. 299/-
                      </option>
                      <option value="125 CC to 199 CC - Rs. 399/-">
                        125 CC to 199 CC &nbsp; - &nbsp; Rs. 399/-
                      </option>
                      <option value="200 CC to 299 CC - Rs. 499/-">
                        200 CC to 299 CC &nbsp; - &nbsp; Rs. 499/-
                      </option>
                      <option value="300 CC to 350 CC - Rs. 599/-">
                        300 CC to 350 CC &nbsp; - &nbsp; Rs. 599/-
                      </option>
                      <option value="Above 350 CC - Rs. 999/-">
                        Above 350 CC &nbsp; - &nbsp; Rs. 999/-
                      </option>
                      <option value="don't remember">Don't remember</option>



                </select>

                <input
                  required
                  className="select-bike-inp text-center"
                  type="text"
                  placeholder="*Enter your city or address"
                  name="address"
                  onChange={handleInput}
                />
                <input
                  className="select-bike-inp text-center"
                  type="text"
                  placeholder="Special request..."
                  name="message"
                  onChange={handleInput}
                />

{
  error?
  <div className="area-location-header-col-2-form-error">{error}</div>
 :"" 
}
                
                <button className="select-bike-btn ">
                  <div className="">BOOK SERVICE</div>{" "}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingComponent;
