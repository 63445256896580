import React from 'react'
import { Link } from 'react-router-dom'

const LandingBikeBrand = () => {
  return (
    <div>
        
      <div className="bike-brand-location">
        <div className="landing-page-location-heading">
          <span>Brands </span>We Service at <span>Home</span> by{" "}
          <span>Experts</span>
        </div>

        <div>
          <div className="bike-brand-location-content">
            <span title="TVS doorstep bike service at home"  to="/bike/tvs">TVS</span> &nbsp;&nbsp; / &nbsp;&nbsp;
            <span title="Bajaj doorstep bike service at home"  to="/bike/bajaj">Bajaj</span> &nbsp;&nbsp; / &nbsp;&nbsp;
            <span title="Royal Enfield doorstep bike service at home"  to="/bike/royal-enfield">Royal Enfield</span> &nbsp;&nbsp; / &nbsp;&nbsp;
            <span title="Yamaha doorstep bike service at home"  to="/bike/yamaha">Yamaha</span> &nbsp;&nbsp; / &nbsp;&nbsp;
            <span title="Honda doorstep bike service at home"  to="/bike/honda">Honda</span> &nbsp;&nbsp; / &nbsp;&nbsp;
            <span title="Hero doorstep bike service at home"  to="/bike/hero">Hero</span> &nbsp;&nbsp; / &nbsp;&nbsp;
            <span title="Suzuki doorstep bike service at home"  to="/bike/suzuki">Suzuki</span> &nbsp;&nbsp; / &nbsp;&nbsp;
            <span title="KTM doorstep bike service at home"  to="/bike/ktm">KTM</span> &nbsp;&nbsp; / &nbsp;&nbsp;
            <span title="Jawa doorstep bike service at home"  to="/bike/jawa">Jawa</span> &nbsp;&nbsp; / &nbsp;&nbsp;
            <span title="Harley Davidson doorstep bike service at home"  to="/bike/harley-davidson">Harley Davidson</span> &nbsp;&nbsp; / &nbsp;&nbsp;
            <span title="Ducati doorstep bike service at home"  to="/bike/ducati">Ducati</span> &nbsp;&nbsp; / &nbsp;&nbsp;
            <span title="Kawasaki doorstep bike service at home"  to="/bike/kawasaki">Kawasaki</span> &nbsp;&nbsp; / &nbsp;&nbsp;
            <span title="Benelli doorstep bike service at home"  to="/bike/benelli">Benelli</span> &nbsp;&nbsp; / &nbsp;&nbsp;
            <span title="Triumph doorstep bike service at home"  to="/bike/triumph">Triumph</span> &nbsp;&nbsp; / &nbsp;&nbsp;
            <span title="Indian doorstep bike service at home"  to="/bike/indian">Indian</span> &nbsp;&nbsp; / &nbsp;&nbsp;
            <span title="BMW doorstep bike service at home"  to="/bike/bmw">BMW</span> &nbsp;&nbsp; / &nbsp;&nbsp;
            <span title="Aprilia doorstep bike service at home"  to="/bike/aprilia">Aprilia</span> &nbsp;&nbsp; / &nbsp;&nbsp;
            <span title="Yezdi doorstep bike service at home"  to="/bike/yezdi">Yezdi</span> &nbsp;&nbsp; / &nbsp;&nbsp;
            <span title="Husqvarna doorstep bike service at home"  to="/bike/husqvarna">Husqvarna</span>
          </div>
        
        
        
      </div>

      <div className="landing-page-button">
        <a href="#book-form">Book Now</a>
      </div>

      {/* ----------------------------- bike brands end -------------------------- */}

    </div>
      
    </div>
  )
}

export default LandingBikeBrand