import React from 'react'
import { Link } from 'react-router-dom'
import CountUp from "react-countup";
import NumberForm from '../forms/NumberForm';

const NoidaPageHeader = () => {
  return (
    <div>

             
<div className="area-location-header">
          <div className="area-location-header-content">
            <div className="area-location-header-col-1">
              <div className="area-location-header-col-1-headings">
                <h1 className="area-location-header-col-1-heading-1">
                  {/* Bike Service & Repair at Your Doorstep */}
                  Bike Repair at Home in <div className="text-capitalize">Noida</div> Starting at <div className="text-capitalize">₹299 </div>
                </h1>
                <h2 className="area-location-header-col-1-heading-2">
                  Book Expert Mechanic Now
                   {/* to Fix your <br />
                  Bike at Your Home */}
                </h2>
              </div>
  

              <div className="area-location-header-col-1-buttons">

                <div className="area-location-header-col-1-button">
                  <Link
                    title="doorstep bike service at home"
                    target="_blank"
                    to="tel:+91-9658 124 124"
                  >
                    <i className="fa-solid fa-phone-volume"></i> &nbsp; Call For
                    Offers & Booking
                  </Link>
                </div>
              </div>

              <div className="area-location-header-col-1-increment-cols">
                <div className="area-location-header-col-1-increment-col">
                  <div className="area-location-header-col-1-increment-col-number">
                    <CountUp end={4} duration="2" enableScrollSpy />.
                    <CountUp end={7} duration="7" enableScrollSpy />/ 5
                  </div>
                  <div className="area-location-header-col-1-increment-col-text">
                    Based on 1,00,000+ <br />
                    Reviews
                  </div>
                </div>
                <div className="area-location-header-col-1-increment-col">
                  <div className="area-location-header-col-1-increment-col-number">
                    <CountUp end={100000} duration="3" enableScrollSpy /> +
                  </div>
                  <div className="area-location-header-col-1-increment-col-text">
                    Happy <br />
                    Customers
                  </div>
                </div>
              </div>
            </div>

            <div id="location-form" className="area-location-header-col-2">
              <NumberForm heading="Noida’s Trusted Bike Service"/>
            </div>
          </div>
        </div>
        
        
    </div>
  )
}

export default NoidaPageHeader