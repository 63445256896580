import React, { useContext, useState } from 'react'
import { useNavigate } from "react-router-dom";
import LoadingComponent from './LoadingComponent';
import AuthContext from '../../context/AuthContext';



const ContactComponent = () => {


  const [loading, setLoading] = useState(false);
  
    // -----------------------------------------------------------------------------------------------------------------------------------------------------------
    // form submit  -----------------------------------------------------------------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  
    const pageFullUrl = window.location.href;
    console.log(pageFullUrl);
  
    // router navigate  ================================================================================================================
    const navigate = useNavigate();
  
    // all useStates  ================================================================================================================
  
    const [error, setError] = useState(null);
  
    const [data, setData] = useState({
      formUrl: pageFullUrl,
      formType: "Contact Us Page",
      phone: "",
    });
  
    const handlePhoneNumber = (e) => {
      const value = e.target.value;
      setError(null)
  
      if (value.length <= 10) {
        setData({
          ...data,
          phone: value,
        });
      }
    };

    const handleInput = (e) =>{

      const value = e.target.value;
      const name = e.target.name;

      setData({
        ...data,
        [name] : value
      })
      
    }
  
    const { createBooking } = useContext(AuthContext);
  
    const submitForm = async (e) => {
      setLoading(true);
      e.preventDefault();
  
      if (data.phone.length != 10) {
        setError("enter the valid number");
      } else {
  
        await createBooking(
          data,
          setError,
          navigate
        );
      }
      setLoading(false);
    };
  
  
  return (
    <div>
      
      {loading ? (
        <div className="data-loading">
          <LoadingComponent />
        </div>
      ) : (
        ""
      )}

      <div className='contact-mn' >

        <div className='contact-page'>

          {/* col-1 */}
          <div className='contact-col-1'>
            <div className='cont-map'>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224219.58116418152!2d77.12350783724641!3d28.58684560461558!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce55b3ce9ced9%3A0x8a0b9a6788107f2f!2sGarage%20on%20Call!5e0!3m2!1sen!2sin!4v1704543735517!5m2!1sen!2sin" allowfullscreen="" title='goc' loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224219.58116418152!2d77.12350783724641!3d28.58684560461558!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce55b3ce9ced9%3A0x8a0b9a6788107f2f!2sGarage%20on%20Call!5e0!3m2!1sen!2sin!4v1704543735517!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
            </div>
          </div>

          {/* col-2 */}
          <div className='contact-col-2'>
            <div className='contact-col-2-h1'>Contact Us</div>
            <div className='contact-col-2-h2'>Welcome to <span> <i class="fa-solid fa-screwdriver-wrench"></i></span> Garage On Call</div>
            <h1 className='contact-col-2-h3'>We're here to help you</h1>
   
            <div className='contact-col-2-h4'>Fill out the form to contact our team.</div>
            {/* contact form */}

            {
            error?
            <div className='header-c-error'>{error}</div>
            :""
          }

            
            <form className='contact-frm'  onSubmit={submitForm} >
              <div>
                <input required placeholder='* Your Name ' name='name'  onChange={handleInput}  />
                <input required placeholder='* Your Number' type='number' name='phone'  onChange={handlePhoneNumber} value={data.phone}  />
              </div>
                <input placeholder='Your Address or City' name='address'  onChange={handleInput}  />
              <input placeholder='Subject' name='subject'  onChange={handleInput}  />
              <textarea rows="3" placeholder='How can we help you?' name='message'  onChange={handleInput} />
              <button>Contact</button>
            </form>
            {/* form end */}
          </div>
          {/* col-2 end */}
        </div>


 
        {/* <div className='contact-we-assist'>
          <div className='contact-w-a-heading'>How Can We Assist You?</div>
          <div className='contact-w-a-content'>
            <ul>
            <li className='contact-w-a-p'><span>Service Booking: </span> &nbsp;  Need to schedule a service for your bike or scooter? Visit our website or give us a call, and our team will guide you through the booking process.</li>
            <li className='contact-w-a-p'><span>General Inquiries: </span>  &nbsp;  Have questions about our services, pricing, or service areas? Feel free to reach out via phone or email, and we'll provide the information you need.</li>
            <li className='contact-w-a-p'><span>Feedback and Suggestions:  </span> &nbsp; Your feedback is valuable to us. Whether you've recently used our services or have suggestions for improvement, we want to hear from you. </li>
            

            </ul>
          </div>
        </div>


        <div className='contact-operating-hours'>
          <div className='contact-o-h-heading'>Operating Hours</div>
          <div className='contact-o-h-p'>Our customer support team is available during regular business hours to assist you. If you require urgent assistance, please contact our mobile helpline for prompt attention.</div>
        </div> */}




        <div className='contact-top'>

          {/* c-t-col-1 */}
          <div className='c-t-col'>
            <div className='c-t-icon'><i class="fa-solid fa-location-dot"></i></div>
            <div className='c-t-text'>
              <div className='c-t-t-h'>Location :</div>
              <div className='c-t-t-t'>
                {/* 520, 5th Floor, Ocean Complex, <br />Sector-18, Noida - 201301 */}
                3rd Floor, Ocean Complex, 313, <br/> Sector 18, Noida, Uttar Pradesh 201301
                </div>
            </div>

          </div>

          {/* c-t-col-3 */}
          <div className='c-t-col'>
            <div className='c-t-icon'><i class="fa-solid fa-phone-volume"></i></div>
            <div className='c-t-text'>
              <div className='c-t-t-h'>Call :</div>
              <div className='c-t-t-t'> 0120 4225828</div>
            </div>
          </div>

          {/* c-t-col-2 */}
          <div className='c-t-col'>
            <div className='c-t-icon'><i class="fa-solid fa-envelope-open-text"></i></div>
            <div className='c-t-text'>
              <div className='c-t-t-h'>Email :</div>
              <div className='c-t-t-t'>support@garageoncall.com</div>
            </div>
          </div>

          {/* c-t-col-4 */}
          <div className='c-t-col'>
            <div className='c-t-icon'><i class="fa-brands fa-whatsapp"></i></div>
            <div className='c-t-text'>
              <div className='c-t-t-h'>WhatsApp :</div>
              <div className='c-t-t-t'> +91 9658-124-124</div>
            </div>
          </div>

          <div className='contact-connect'>At Garage On Call, we are committed to providing you with a seamless and satisfying two-wheeler service experience. Contact us today, and let us take care of your bike or scooter maintenance needs at your doorstep!</div>

        </div>




      </div>

    </div>
  )
}

export default ContactComponent