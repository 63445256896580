import React from 'react'
import Navbar from '../../components/user/Navbar'
import Footer from '../../components/user/Footer'
import BookingComponent from '../../components/user/BookingComponent'
import ButtonComponent from '../../components/user/ButtonComponent'
import { Helmet } from 'react-helmet';


const BookingPage = () => {
  return (
    <div>
      <Helmet>
        <title>Affordable At-Home Bike Repair in Noida & Delhi NCR</title>
        <meta name="description" content="Experience hassle-free bike maintenance and repairs right at your doorstep! Book your service today for ultimate convenience and peace of mind." />
      </Helmet>
        
      <div className="nav-fix"><Navbar /></div>

        <BookingComponent tag="h1" heading="Booking Page Booking Form"/>
        

        <Footer />
        {/* <ButtonComponent /> */}

    </div>
  )
}

export default BookingPage