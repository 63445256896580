import React from 'react'
import LocationPageComponent from '../../components/user/LocationPageComponent'
import Navbar from '../../components/user/Navbar'
import Footer from '../../components/user/Footer'
import ButtonComponent from '../../components/user/ButtonComponent'
import { Helmet } from 'react-helmet';

const LocationPage = () => {
  return (
    <div>
      <Helmet>
        <title>Bike Repair, Your Location, Your Convenience</title>
        <meta name="description" content="
        Experience hassle-free bike maintenance and repairs brought right to your doorstep with our mobile service. Get your bike serviced and repaired hassle-free at your doorstep or visit our location in Noida, Greater Noida, Ghaziabad, Delhi, Faridabad and Gurgaon.
        " />
      </Helmet>
      
       <div className="nav-fix"><Navbar /></div>
        <LocationPageComponent />
        <Footer />
        {/* <ButtonComponent /> */}
    </div>
  )
}

export default LocationPage