import React from 'react'

const  LandingCheckList = ({bike, model, location}) => {
  return (
    <div>
        
        <div className="area-location-g-service">
          <div className="area-location-g-service-content">
            <h2 className="area-location-g-service-heading">
                <>
                Routine Service & Repair <span>Check-list</span>&nbsp;
                {/* in<span className="text-capitalize">&nbsp;{location}</span> */}
                </>
            </h2>
            <div className="area-location-g-service-text">
              From engine work to parts replacement, wiring faults, lock/unlock
              issues, and wheel problems,
              <br /> we handle all major repairs right at home for your convenience.
            </div>
            <div className="area-location-g-service-col">
              <div className="area-location-g-service-col-list">
                <span className="area-location-g-service-col-list-icon">
                  &#10095;&#10095;
                </span>
                <span>Engine Oil Change (Price Extra)</span>
              </div>
              <div className="area-location-g-service-col-list">
                <span className="area-location-g-service-col-list-icon">
                  &#10095;&#10095;
                </span>
                <span>Oil Filter Clean (if Replace Charges)</span>
              </div>
              <div className="area-location-g-service-col-list">
                <span className="area-location-g-service-col-list-icon">
                  &#10095;&#10095;
                </span>
                <span>Air Filter Clean (if Replace Charges)</span>
              </div>
              <div className="area-location-g-service-col-list">
                <span className="area-location-g-service-col-list-icon">
                  &#10095;&#10095;
                </span>
                <span>Spark Plug Clean (if Replace Charges)</span>
              </div>
              <div className="area-location-g-service-col-list">
                <span className="area-location-g-service-col-list-icon">
                  &#10095;&#10095;
                </span>
                <span>Tightening of Screws, Bolts & Nuts</span>
              </div>
              <div className="area-location-g-service-col-list">
                <span className="area-location-g-service-col-list-icon">
                  &#10095;&#10095;
                </span>
                <span>Average and Performance Check-up</span>
              </div>
              <div className="area-location-g-service-col-list">
                <span className="area-location-g-service-col-list-icon">
                  &#10095;&#10095;
                </span>
                <span>Brakes – Front & Rear Adjust</span>
              </div>
              <div className="area-location-g-service-col-list">
                <span className="area-location-g-service-col-list-icon">
                  &#10095;&#10095;
                </span>
                <span>Driven Chain Basic Cleaning</span>
              </div>
              <div className="area-location-g-service-col-list">
                <span className="area-location-g-service-col-list-icon">
                  &#10095;&#10095;
                </span>
                <span>Carburettor Basic Check-up</span>
              </div>
              <div className="area-location-g-service-col-list">
                <span className="area-location-g-service-col-list-icon">
                  &#10095;&#10095;
                </span>
                <span>Minor Electrical Check-up</span>
              </div>
              <div className="area-location-g-service-col-list">
                <span className="area-location-g-service-col-list-icon">
                  &#10095;&#10095;
                </span>
                <span>Battery General Check-up</span>
              </div>
              <div className="area-location-g-service-col-list">
                <span className="area-location-g-service-col-list-icon">
                  &#10095;&#10095;
                </span>
                <span>Basic Engine Inspection</span>
              </div>
              <div className="area-location-g-service-col-list">
                <span className="area-location-g-service-col-list-icon">
                  &#10095;&#10095;
                </span>
                <span>Basic Fork Inspection</span>
              </div>
              <div className="area-location-g-service-col-list">
                <span className="area-location-g-service-col-list-icon">
                  &#10095;&#10095;
                </span>
                <span>Basic Hand Cleaning</span>
              </div>
              <div className="area-location-g-service-col-list">
                <span className="area-location-g-service-col-list-icon">
                  &#10095;&#10095;
                </span>
                <span>Free Pick and Drop</span>
              </div>
            </div>

            <div id='why-us' className="landing-page-button">

                <a href="#book-form">Book Now</a>
                
            </div>
            
          </div>
        </div>

    </div>
  )
}

export default  LandingCheckList