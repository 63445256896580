import React from "react";
import { Route, Routes } from "react-router-dom";
import FilterBlogPage from "./pages/user/FilterBlogPage";
import BlogPage from "./pages/user/BlogPage";
import BlogErrorPage from "./pages/user/BlogErrorPage";
import BlogServicing from "./components/blogs/SingleBlogs/BlogServicing";
import BlogBenefits from "./components/blogs/SingleBlogs/BlogBenefits";
import BlogTips from "./components/blogs/SingleBlogs/BlogTips";
import BlogCorona from "./components/blogs/SingleBlogs/BlogCorona";
import BlogDoorstepBikeServicing from "./components/blogs/SingleBlogs/BlogDoorstepBikeServicing";
import BlogServiceHom from "./components/blogs/SingleBlogs/BlogServiceHom";
import BlogGarageOnCall from "./components/blogs/SingleBlogs/BlogGarageOnCall";
import RegularMotorcycleServicing from "./components/blogs/SingleBlogs/RegularMotorcycleServicing";
import RightTwoWheelerForYourNeeds from "./components/blogs/SingleBlogs/RightTwoWheelerForYourNeeds";
import IncreasingLifespanYourScooter from "./components/blogs/SingleBlogs/IncreasingLifespanYourScooter";
import MotorcycleMaintenanceHome from "./components/blogs/SingleBlogs/MotorcycleMaintenanceHome";
import EssentialSafetyGearMotorcyclists from "./components/blogs/SingleBlogs/EssentialSafetyGearMotorcyclists";
import BestBeginnerMotorcycles from "./components/blogs/SingleBlogs/BestBeginnerMotorcycles";
import CheckingAndAdjustingTirePressure from "./components/blogs/SingleBlogs/CheckingAndAdjustingTirePressure";
import TipsForTwoWheelers from "./components/blogs/SingleBlogs/TipsForTwoWheelers";
import MotorcyclesFluidLevels from "./components/blogs/SingleBlogs/MotorcyclesFluidLevels";
import CleanAndLubricateYourBikeChain from "./components/blogs/SingleBlogs/CleanAndLubricateYourBikeChain";
import ImportanceOfRegularTuneUps from "./components/blogs/SingleBlogs/ImportanceOfRegularTuneUps";
import KeepingYourMotorcycleClean from "./components/blogs/SingleBlogs/KeepingYourMotorcycleClean";
import MotorcycleBrakeMaintenance from "./components/blogs/SingleBlogs/MotorcycleBrakeMaintenance";
import MotorcycleElectricalSystemMaintenance from "./components/blogs/SingleBlogs/MotorcycleElectricalSystemMaintenance";
import PreventativeMaintenanceChecklist from "./components/blogs/SingleBlogs/PreventativeMaintenanceChecklist";
import MotorcycleChainMaintenance from "./components/blogs/SingleBlogs/MotorcycleChainMaintenance";
import MotorcycleCareAndCleaning from "./components/blogs/SingleBlogs/MotorcycleCareAndCleaning";
import BrakeMaintenanceAndInspection from "./components/blogs/SingleBlogs/BrakeMaintenanceAndInspection";
import MaintenanceMistakes from "./components/blogs/SingleBlogs/MaintenanceMistakes";
import EngineIssuesInMotorcycles from "./components/blogs/SingleBlogs/EngineIssuesInMotorcycles";
import UnderstandingMotorcycleSuspension from "./components/blogs/SingleBlogs/UnderstandingMotorcycleSuspension";
import WinterizingYourBike from "./components/blogs/SingleBlogs/WinterizingYourBike";
import SignsOfWornOutPiston from "./components/blogs/SingleBlogs/SignsOfWornOutPiston";
import ChangeEngineOil from "./components/blogs/SingleBlogs/ChangeEngineOil";
import MainComponentsOfBikeEngine from "./components/blogs/SingleBlogs/MainComponentsOfBikeEngine";
import MaintainCoolingSystem from "./components/blogs/SingleBlogs/MaintainCoolingSystem";
import SyntheticBikeEngine from "./components/blogs/SingleBlogs/SyntheticBikeEngine";
import FuelInjectedEngine from "./components/blogs/SingleBlogs/FuelInjectedEngine";
import CylinderHeadBikeEngine from "./components/blogs/SingleBlogs/CylinderHeadBikeEngine";
import ImprovePerformance from "./components/blogs/SingleBlogs/ImprovePerformance";
import AdjustValveClearance from "./components/blogs/SingleBlogs/AdjustValveClearance";
import EngineOverheating from "./components/blogs/SingleBlogs/EngineOverheating";
import ReplaceSparkPlug from "./components/blogs/SingleBlogs/ReplaceSparkPlug";
import FunctionOfCrankshaft from "./components/blogs/SingleBlogs/FunctionOfCrankshaft";
import EngineOilChanges from "./components/blogs/SingleBlogs/EngineOilChanges";
import MaintainAirFilter from "./components/blogs/SingleBlogs/MaintainAirFilter";
import TimingChainFunction from "./components/blogs/SingleBlogs/TimingChainFunction";
import AutomaticTransmission from "./components/blogs/SingleBlogs/AutomaticTransmission";
import LiquidCooled from "./components/blogs/SingleBlogs/LiquidCooled";
import FailingBikeEngine from "./components/blogs/SingleBlogs/FailingBikeEngine";
import CheckOilLevel from "./components/blogs/SingleBlogs/CheckOilLevel";
import BasicTuneUp from "./components/blogs/SingleBlogs/BasicTuneUp";
import ExhaustValve from "./components/blogs/SingleBlogs/ExhaustValve";
import ReplaceFuelFilter from "./components/blogs/SingleBlogs/ReplaceFuelFilter";
import IgnitionSystem from "./components/blogs/SingleBlogs/IgnitionSystem";
import EngineMisfiring from "./components/blogs/SingleBlogs/EngineMisfiring";
import FunctionOfPistonRings from "./components/blogs/SingleBlogs/FunctionOfPistonRings";
import MaintainDriveBelt from "./components/blogs/SingleBlogs/MaintainDriveBelt";
import TurbochargerWork from "./components/blogs/SingleBlogs/TurbochargerWork";
import ReplaceTimingBelt from "./components/blogs/SingleBlogs/ReplaceTimingBelt";
import EngineCoolingFan from "./components/blogs/SingleBlogs/EngineCoolingFan";
import FourValveEngine from "./components/blogs/SingleBlogs/FourValveEngine";
import ThrottleBodyInBikeEngine from "./components/blogs/SingleBlogs/ThrottleBodyInBikeEngine";
import AlternatorInBike from "./components/blogs/SingleBlogs/AlternatorInBike";
import TroubleshootNoisyBike from "./components/blogs/SingleBlogs/TroubleshootNoisyBike";
import MaintainGearbox from "./components/blogs/SingleBlogs/MaintainGearbox";
import CloggedFuelInjector from "./components/blogs/SingleBlogs/CloggedFuelInjector";
import VTwinEngine from "./components/blogs/SingleBlogs/VTwinEngine";
import ConvenienceOfDoorstep from "./components/blogs/SingleBlogs/ConvenienceOfDoorstep";
import MaintenanceCrucial from "./components/blogs/SingleBlogs/MaintenanceCrucial";
import NeedsImmediateAttention from "./components/blogs/SingleBlogs/NeedsImmediateAttention";
import MaintainingRoyalEnfield from "./components/blogs/SingleBlogs/MaintainingRoyalEnfield";
import RightDoorstepServiceProvider from "./components/blogs/SingleBlogs/RightDoorstepServiceProvider";
import ElectricBikeMaintenanceSchedule from "./components/blogs/SingleBlogs/ElectricBikeMaintenanceSchedule";
import MaintainingElectricScooter from "./components/blogs/SingleBlogs/MaintainingElectricScooter";
import ExtendEVBatteryLife from "./components/blogs/SingleBlogs/ExtendEVBatteryLife";
import IdentifyFixBattery from "./components/blogs/SingleBlogs/IdentifyFixBattery";
import ElectricBikeMotorRunningSmoothly from "./components/blogs/SingleBlogs/ElectricBikeMotorRunningSmoothly";
import MaintainOptimalPerformance from "./components/blogs/SingleBlogs/MaintainOptimalPerformance";
import PreRideSafetyCheck from "./components/blogs/SingleBlogs/PreRideSafetyCheck";
import StoreElectricScooter from "./components/blogs/SingleBlogs/StoreElectricScooter";
import SafelyTransportElectricBike from "./components/blogs/SingleBlogs/SafelyTransportElectricBike";
import CommonElectricBikeIssues from "./components/blogs/SingleBlogs/CommonElectricBikeIssues";
import CleaningElectricScooter from "./components/blogs/SingleBlogs/CleaningElectricScooter";
import RegularTireChecks from "./components/blogs/SingleBlogs/RegularTireChecks";
import ProsConsElectricScooter from "./components/blogs/SingleBlogs/ProsConsElectricScooter";
import GuideRoutineElectricBike from "./components/blogs/SingleBlogs/GuideRoutineElectricBike";
import TopToolsElectricBike from "./components/blogs/SingleBlogs/TopToolsElectricBike";
import UpgradingYourElectricBike from "./components/blogs/SingleBlogs/UpgradingYourElectricBike";
import ElectricScooterTires from "./components/blogs/SingleBlogs/ElectricScooterTires";
import TipsProtectingElectricBike from "./components/blogs/SingleBlogs/TipsProtectingElectricBike";
import MotorcycleWithoutEngineOil from "./components/blogs/SingleBlogs/MotorcycleWithoutEngineOil";

const BlogRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path="" element={<BlogPage />}></Route>
        <Route
          path="/category/bike"
          element={<FilterBlogPage type="bike" />}
        ></Route>
        <Route
          path="/category/scooty"
          element={<FilterBlogPage type="scooty" />}
        ></Route>
        <Route
          path="/category/ev"
          element={<FilterBlogPage type="Electric Vehicle" />}
        ></Route>
        <Route path="/*" element={<BlogErrorPage />}></Route>

        {/* all blogs start ---------------------------------------------------------------------------------------------------------------- */}

        <Route
          path="/what-happens-if-your-motorcycle-runs-without-engine-oil"
          element={<MotorcycleWithoutEngineOil />}
        ></Route>


        <Route
          path="/porsche-cayenne-coupe-launched-in-india"
          element={<BlogServicing />}
        ></Route>

        
        <Route
          path="/renault-sells-over-18000-units-with-the-triber-mpv-in-india"
          element={<BlogBenefits />}
        ></Route>
        <Route
          path="/tata-hexa-offering-benefits-upto-₹-2-2-lakh"
          element={<BlogTips />}
        ></Route>
        <Route path="/corona-blog" element={<BlogCorona />}></Route>
        <Route
          path="/doorstep-bike-servicing-and-repairing"
          element={<BlogDoorstepBikeServicing />}
        ></Route>
        <Route
          path="/bike-servicing-at-home"
          element={<BlogServiceHom />}
        ></Route>
        <Route
          path="/no-local-garage-call-garage-on-call"
          element={<BlogGarageOnCall />}
        ></Route>
        <Route
          path="/regular-motorcycle-servicing"
          element={<RegularMotorcycleServicing />}
        ></Route>
        <Route
          path="/right-two-Wheeler-for-your-needs"
          element={<RightTwoWheelerForYourNeeds />}
        ></Route>
        <Route
          path="/tips-for-increasing-the-lifespan-your-scooter"
          element={<IncreasingLifespanYourScooter />}
        ></Route>
        <Route
          path="/basic-motorcycle-maintenance-at-home"
          element={<MotorcycleMaintenanceHome />}
        ></Route>
        <Route
          path="/essential-safety-gear-for-motorcyclists"
          element={<EssentialSafetyGearMotorcyclists />}
        ></Route>
        <Route
          path="/top-10-best-beginner-motorcycles-for-new-riders"
          element={<BestBeginnerMotorcycles />}
        ></Route>
        <Route
          path="/checking-and-adjusting-tire-pressure"
          element={<CheckingAndAdjustingTirePressure />}
        ></Route>
        <Route
          path="/maintenance-tips-for-two-wheelers"
          element={<TipsForTwoWheelers />}
        ></Route>
        <Route
          path="/check-your-motorcycles-fluid-levels"
          element={<MotorcyclesFluidLevels />}
        ></Route>
        <Route
          path="/clean-and-lubricate-your-bike-chain"
          element={<CleanAndLubricateYourBikeChain />}
        ></Route>
        <Route
          path="/importance-of-regular-tune-ups"
          element={<ImportanceOfRegularTuneUps />}
        ></Route>
        <Route
          path="/keeping-your-motorcycle-clean"
          element={<KeepingYourMotorcycleClean />}
        ></Route>
        <Route
          path="/motorcyclebrake-maintenance"
          element={<MotorcycleBrakeMaintenance />}
        ></Route>
        <Route
          path="/motorcycle-electrical-system-maintenance"
          element={<MotorcycleElectricalSystemMaintenance />}
        ></Route>
        <Route
          path="/preventative-maintenance-checklist"
          element={<PreventativeMaintenanceChecklist />}
        ></Route>
        <Route
          path="/motorcycle-chain-maintenance"
          element={<MotorcycleChainMaintenance />}
        ></Route>
        <Route
          path="/motorcycle-care-and-cleaning"
          element={<MotorcycleCareAndCleaning />}
        ></Route>
        <Route
          path="/brake-maintenance-and-inspection"
          element={<BrakeMaintenanceAndInspection />}
        ></Route>
        <Route
          path="/maintenanc-mistakes"
          element={<MaintenanceMistakes />}
        ></Route>
        <Route
          path="/engine-issues-in-motorcycles"
          element={<EngineIssuesInMotorcycles />}
        ></Route>
        <Route
          path="/understanding-motorcycle-suspension"
          element={<UnderstandingMotorcycleSuspension />}
        ></Route>
        <Route
          path="/winterizing-your-bike"
          element={<WinterizingYourBike />}
        ></Route>
        <Route
          path="/signs-of-worn-out-piston"
          element={<SignsOfWornOutPiston />}
        ></Route>
        <Route path="/change-engine-oil" element={<ChangeEngineOil />}></Route>
        <Route
          path="/main-components-of-bike-engine"
          element={<MainComponentsOfBikeEngine />}
        ></Route>
        <Route
          path="/maintain-cooling-system"
          element={<MaintainCoolingSystem />}
        ></Route>
        <Route
          path="/benefits-using-synthetic-oil-in-bike-engine"
          element={<SyntheticBikeEngine />}
        ></Route>
        <Route
          path="/fuel-injected-engine-work-in-bikes"
          element={<FuelInjectedEngine />}
        ></Route>
        <Route
          path="/role-of-cylinder-head-in-bike-engine"
          element={<CylinderHeadBikeEngine />}
        ></Route>
        <Route
          path="/improve-performance-of-bike-engine"
          element={<ImprovePerformance />}
        ></Route>
        <Route
          path="/adjust-valve-clearance-on-bike-engine"
          element={<AdjustValveClearance />}
        ></Route>
        <Route
          path="/reasons-for-engine-overheating-in-scooties"
          element={<EngineOverheating />}
        ></Route>
        <Route
          path="/how-do-replace-the-spark-plug-on-scooty"
          element={<ReplaceSparkPlug />}
        ></Route>
        <Route
          path="/function-of-crankshaft-in-bike-engine"
          element={<FunctionOfCrankshaft />}
        ></Route>
        <Route
          path="/importance-of-regular-engine-oil-changes-for-bikes"
          element={<EngineOilChanges />}
        ></Route>
        <Route
          path="/maintain-air-filter-in-scooty"
          element={<MaintainAirFilter />}
        ></Route>
        <Route
          path="/function-of-timing-chain-in-bike-engine"
          element={<TimingChainFunction />}
        ></Route>
        <Route
          path="automatic-transmission-work-in-scooty"
          element={<AutomaticTransmission />}
        ></Route>
        <Route
          path="/benefits-of-liquid-cooled-engine-for-bikes"
          element={<LiquidCooled />}
        ></Route>
        <Route
          path="/signs-of-failing-bike-engine"
          element={<FailingBikeEngine />}
        ></Route>
        <Route
          path="/how-do-check-oil-level-in-bike-engine"
          element={<CheckOilLevel />}
        ></Route>
        <Route
          path="/how-do-perform-basic-tune-up-on-scooty-engine"
          element={<BasicTuneUp />}
        ></Route>
        <Route
          path="/purpose-of-exhaust-valve-in-bike-engine"
          element={<ExhaustValve />}
        ></Route>
        <Route
          path="/how-do-replace-fuel-filter-on-bike"
          element={<ReplaceFuelFilter />}
        ></Route>
        <Route
          path="/role-of-ignition-system-in-bike-engine"
          element={<IgnitionSystem />}
        ></Route>
        <Route
          path="/common-causes-of-engine-misfiring-in-scooties"
          element={<EngineMisfiring />}
        ></Route>
        <Route
          path="/function-of-piston-rings-in-bike-engine"
          element={<FunctionOfPistonRings />}
        ></Route>
        <Route
          path="/maintain-drive-belt-on-scooty"
          element={<MaintainDriveBelt />}
        ></Route>
        <Route
          path="/turbocharger-work-in-bike-engine"
          element={<TurbochargerWork />}
        ></Route>
        <Route
          path="/replace-timing-belt-on-scooty"
          element={<ReplaceTimingBelt />}
        ></Route>
        <Route
          path="/how-does-bike-engine-cooling-fan-work"
          element={<EngineCoolingFan />}
        ></Route>
        <Route
          path="/four-valve-engine-in-bikes"
          element={<FourValveEngine />}
        ></Route>
        <Route
          path="/purpose-of-throttle-body-in-bike-engine"
          element={<ThrottleBodyInBikeEngine />}
        ></Route>
        <Route
          path="/function-of-alternatorin-bike-engine"
          element={<AlternatorInBike />}
        ></Route>
        <Route
          path="/how-do-troubleshoot-noisy-bike-engine"
          element={<TroubleshootNoisyBike />}
        ></Route>
        <Route
          path="/how-do-maintain-gearbox-on-scooty"
          element={<MaintainGearbox />}
        ></Route>
        <Route
          path="/signs-of-clogged-fuel-injector-in-bikes"
          element={<CloggedFuelInjector />}
        ></Route>
        <Route
          path="/benefits-of-V-twin-engine-in-bikes"
          element={<VTwinEngine />}
        ></Route>
        <Route
          path="/convenience-of-doorstep-two-wheeler-servicing"
          element={<ConvenienceOfDoorstep />}
        ></Route>
        <Route
          path="/why-regular-maintenance-is-crucial-for-your-bike-and-scooty"
          element={<MaintenanceCrucial />}
        ></Route>
        <Route
          path="/common-signs-your-bike-or-scooty-needs-immediate-attention"
          element={<NeedsImmediateAttention />}
        ></Route>
        <Route
          path="/top-10-tips-for-maintaining-your-royal-enfield-bike"
          element={<MaintainingRoyalEnfield />}
        ></Route>
        <Route
          path="/how-to-choose-the-right-doorstep-service-provider-for-your-two-wheeler"
          element={<RightDoorstepServiceProvider />}
        ></Route>

        {/* ev blogs ------------------------------------- */}
        <Route
          path="/electric-bike-maintenance-schedule-what-to-do-and-when"
          element={<ElectricBikeMaintenanceSchedule />}
        ></Route>
        <Route
          path="/essential-tips-for-maintaining-your-electric-scooter-brakes"
          element={<MaintainingElectricScooter />}
        ></Route>
        <Route
          path="/how-to-extend-the-battery-life-of-your-electric-scooter"
          element={<ExtendEVBatteryLife />}
        ></Route>
        <Route
          path="/how-to-identify-and-fix-battery-charging-problems-in-electric-scooters"
          element={<IdentifyFixBattery />}
        ></Route>
        <Route
          path="/how-to-keep-your-electric-bike-motor-running-smoothly"
          element={<ElectricBikeMotorRunningSmoothly />}
        ></Route>
        <Route
          path="/how-to-maintain-optimal-performance-of-your-electri-scooter-chain-and-gears"
          element={<MaintainOptimalPerformance />}
        ></Route>
        <Route
          path="/how-to-perform-a-pre-ride-safety-check-on-your-electric-bike"
          element={<PreRideSafetyCheck />}
        ></Route>
        <Route
          path="/how-to-properly-store-your-electric-scooter-during-off-season"
          element={<StoreElectricScooter />}
        ></Route>
        <Route
          path="/how-to-safely-transport-your-electric-bike-or-scooter"
          element={<SafelyTransportElectricBike />}
        ></Route>
        <Route
          path="/how-to-troubleshoot-common-electric-bike-issues"
          element={<CommonElectricBikeIssues />}
        ></Route>
        <Route
          path="/step-by-step-guide-to-cleaning-your-electric-scooter"
          element={<CleaningElectricScooter />}
        ></Route>
        <Route
          path="/the-importance-of-regular-tire-checks-and-maintenance"
          element={<RegularTireChecks />}
        ></Route>
        <Route
          path="/the-pros-and-cons-of-diy-vs-professional-electric-scooter-maintenance"
          element={<ProsConsElectricScooter />}
        ></Route>
        <Route
          path="/the-ultimate-guide-to-routine-electric-bike-maintenance"
          element={<GuideRoutineElectricBike />}
        ></Route>
        <Route
          path="/top-10-tools-every-electric-bike-owner-should-have"
          element={<TopToolsElectricBike />}
        ></Route>
        <Route
          path="/upgrading-your-electric-bike-components-what-you-need-to-know"
          element={<UpgradingYourElectricBike />}
        ></Route>
        <Route
          path="/when-to-replace-your-electric-scooter-tires-and-how-to-do-it"
          element={<ElectricScooterTires />}
        ></Route>
        <Route
          path="/tips-for-protecting-you-electric-bike-from-weather-damage"
          element={<TipsProtectingElectricBike />}
        ></Route>

        {/* all blogs end ---------------------------------------------------------------------------------------------------------------- */}
      </Routes>
    </div>
  );
};

export default BlogRoutes;
