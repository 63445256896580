import React, { useState } from "react";

const NoidaPageFaq = () => {
  const [viewAnswer, setViewAnswer] = useState(0);

  const data = [
    {
      question: "Do you offer scooty service at home in Noida?",
      answer:
        "Yes! We specialize in scooty servicing, including gear fixes, battery swaps, and brake adjustments",
    },
    
    {
      question: "Is free pickup/drop available for bike repairs?",
      answer: "Yes! For major repairs, we offer free pickup and drop off across Noida",
    },
    
    {
      question: "What if my bike needs part replacement during service?",
      answer: "We’ll share a quote upfront. Approve only if you’re satisfied – no forced replacements",
    },
    
    {
      question: "Why choose doorstep service over local garages?",
      answer: "Save time, avoid hidden charges, and enjoy certified care at home. No waiting, no travel, and free pickup/drop for major repairs",
    },
    
    {
      question: "Is home bike service safe?",
      answer: "Absolutely. Our mechanics are background-verified, fully insured, and use sanitized tools. Genuine parts and transparent pricing guaranteed",
    },
    
    {
      question: "How long does bike service at home take?",
      answer: "Most services take 1-3 hours, depending on repairs. Emergency fixes like punctures are done in under 60 minutes",
    },
    
    {
      question: "What’s the cost of bike service at home in Noida?",
      answer: "Full servicing packages begin at ₹299. Check our app for detailed pricing",
    },
    
    {
      question: "Do you cover all areas in Noida for bike service?",
      answer: "Yes! We serve all sectors in Noida, including Sector 62, 137, 168, and beyond. Book via Garage On Call for hassle-free service",
    },
    
    {
      question: " Is home bike service safe?",
      answer: "Yes! Our mechanics are background-verified, insured, and use sanitized tools",
    },
    
    {
      question: "How do I book a bike mechanic near me?",
      answer: "Visit Garage On Call or call +91-9658124124. Select your location, service type, and slot",
    },
    
    {
      question: "Is free pickup/drop available for repairs?",
      answer: "Yes! Free pickup/drop is included for major repairs across Noida",
    },
    
    {
      question: "Are there discounts for first-time users?",
      answer: "Use code GOC20 for 20% off your first doorstep service.",
    },
    
    {
      question: "What if my bike needs part replacement?",
      answer: "We’ll share a quote upfront. Approve only if you’re satisfied – no hidden costs.",
    },
  ];

  return (
    <div>
      <div className="location-faq">
        <div className="location-faq-main">
          <div className="location-faq-heading">
          Solutions for Every <span>Bike Maintenance</span> in <span>Noida</span>
            {/* Lorem <span>ipsum</span> dolor sit amet. */}
          </div>

          {data.map((e, i) => (
            <div className="location-faq-col">
              <div className="location-faq-question">
                <span>
                  <span>Ques {i + 1}. </span> {e.question}
                </span>
                {
                    viewAnswer === i?
                    <i onClick={()=>setViewAnswer(null)} class="fa-solid fa-minus"></i>
                    :
                    <i onClick={()=>setViewAnswer(i)} class="fa-solid fa-plus"></i>
                }
              </div>

              {
                    viewAnswer === i?
              <div className="location-faq-answer">
                <span>Ans. </span> {e.answer}
              </div>
                    :
                    ""
                }




            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NoidaPageFaq;
