import React, { useContext, useEffect, useState } from "react";

import warrantyIcon from "../../images/icon/warranty.svg";
import expertsIcon from "../../images/icon/experts.svg";
import bestcustomerIcon from "../../images/icon/bestcustomer.svg";
import LoadingComponent from "../user/LoadingComponent";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";

const LandingHeaderForm = ({ heading, text }) => {
  const pathname = window.location.pathname;

  const [loading, setLoading] = useState(false);

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  // form submit  -----------------------------------------------------------------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------

  const pageFullUrl = window.location.href;
  console.log(pageFullUrl);

  // router navigate  ================================================================================================================
  const navigate = useNavigate();

  // all useStates  ================================================================================================================

  const [error, setError] = useState(null);

  const [data, setData] = useState({
    formUrl: pageFullUrl,
    formType: heading,
    phone: "",
    bikeBrand: "",
    bikeModel: "",
    bikeCC: "",
    address: "",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setError(null);
    setData({
      ...data,
      [name]: value,
    });

    }

  const handleNumber = (e) => {
    const value = e.target.value;
    setError(null);

    if (value.length <= 10) {
      setData({
        ...data,
        phone: value,
      });
    }

  };

  const handleBrand = (e) => {
    const value = e.target.value;
    setError(null);

    setData({
        ...data,
        bikeBrand : value,
        bikeModel : "",
      });

  }

  const { createBooking, fetchBikes } = useContext(AuthContext);

  const submitForm = async (e) => {
    setLoading(true);
    e.preventDefault();

    if (data.phone.length != 10) {
      setError("enter the valid number");
    } else {
      await createBooking(data, setError, navigate);
    }
    setLoading(false);
  };

  const [bikeData, setBikeData] = useState([]);

  const fetchData = () => fetchBikes(setBikeData);

  useEffect(() => {
    fetchData();
    console.log("bikeData is :", bikeData);
  }, []);

  return (
    <div>
      {loading ? (
        <div className="data-loading">
          <LoadingComponent />
        </div>
      ) : (
        ""
      )}

      <div
        id="location-form"
        className="area-location-header-col-2 landing-page-header"
      >
        <div className="area-location-header-col-2-content">
          <div className="area-location-header-col-2-headings">
            <div className="area-location-header-col-2-heading-1">
              {/* <div className="text-capitalize">&nbsp;{bike}&nbsp;</div> */}
              <div className="text-capitalize">
                {heading ? heading : "Bike Service at Home"}
              </div>
            </div>
            <div className="area-location-header-col-2-heading-1-text">
              <b>{text ? text : ""}</b>
            </div>
            {/* <div className="area-location-header-col-2-heading-2">
                     {heading2?heading2:"Now Get Your Bike Service at Home and Save Your Time and Money!"}

                  </div> */}
          </div>

          <div className="area-location-header-col-2-form">
            <form onSubmit={submitForm}>
              <div className="sb-c3-1-col"></div>

              <h3>Enter your mobile number</h3>

              {error ? (
                <div className="area-location-header-col-2-form-error">
                  {error}
                </div>
              ) : (
                ""
              )}

              <div className="area-location-header-form-col-2">
                <select required onChange={handleBrand} name="bikeBrand">
                  <option value="" disabled selected>
                    --- select brand ---
                  </option>
                  {bikeData.map((e) => (
                    <option value={e.brand}>{e.brand}</option>
                  ))}
                </select>

              

                <select required onChange={handleChange} name="bikeModel" value={data.bikeModel} >
                  <option value="" disabled selected>
                    --- select model ---
                  </option>
                  {bikeData.find((e) => e.brand == data.bikeBrand) ? (
                    <>
                      {bikeData
                        .find((e) => e.brand == data.bikeBrand)
                        .models.map((e) => (
                          <option value={e}>{e}</option>
                        ))}
                      <option value="Other Model">Other Model</option>
                    </>
                  ) : (
                    ""
                  )}
                </select>
              </div>

{
    data.bikeModel?


              <select
                required
                className="select-bike-inp text-center"
                name="bikeCC"
                onChange={handleChange}
              >
                <option disabled selected value="">
                  --- select bike CC ---
                </option>

                <option value="Below 125 CC - Rs. 299/-">
                  Below 125 CC &nbsp; - &nbsp; Rs. 299/-
                </option>
                <option value="125 CC to 199 CC - Rs. 399/-">
                  125 CC to 199 CC &nbsp; - &nbsp; Rs. 399/-
                </option>
                <option value="200 CC to 299 CC - Rs. 499/-">
                  200 CC to 299 CC &nbsp; - &nbsp; Rs. 499/- 
                </option>
                <option value="300 CC to 350 CC - Rs. 599/-">
                  300 CC to 350 CC &nbsp; - &nbsp; Rs. 599/-
                </option>
                <option value="Above 350 CC - Rs. 999/-">
                  Above 350 CC &nbsp; - &nbsp; Rs. 999/-
                </option>
                <option value="don't remember">Don't remember</option>
              </select>
              :
              ""}

              <input
                placeholder="Phone Number*"
                required
                onChange={handleNumber}
                value={data.phone}
              />

{
data.phone.length === 10?
              <select required name="address" value={data.address} onChange={handleChange}>

                <option value="" selected disabled>---Select Location---</option>
                <option value="Delhi">Delhi</option>
                <option value="Noida">Noida</option>
                <option value="Greater Noida">Greater Noida</option>
                <option value="Gurugram">Gurugram</option>
                <option value="Ghaziabad">Ghaziabad</option>
                <option value="Faridabad">Faridabad</option>
                
              </select>
              :
              ""
}

              {pathname === "/bikes/delhi-ncr" ? (
                <button>Book Now</button>
              ) : (
                <button>Book Now</button>
              )}
            </form>

            <div className="area-location-header-col-2-end">
              <div className="area-location-header-col-2-end-col">
                <div className="area-location-header-col-2-end-icon">
                  <img src={warrantyIcon} alt="icon" title="icon" />
                </div>
                <div className="area-location-header-col-2-end-text">
                  10 Days Service Warranty
                </div>
              </div>

              <div className="area-location-header-col-2-end-col">
                <div className="area-location-header-col-2-end-icon">
                  <img src={expertsIcon} alt="icon" title="icon" />
                </div>
                <div className="area-location-header-col-2-end-text">
                  Expert <br />
                  Mechanics
                </div>
              </div>

              <div className="area-location-header-col-2-end-col">
                <div className="area-location-header-col-2-end-icon">
                  <img src={bestcustomerIcon} alt="icon" title="icon" />
                </div>
                <div className="area-location-header-col-2-end-text">
                  Best Customer
                  <br /> Support{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingHeaderForm;
