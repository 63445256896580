import React from 'react'

const LandingNavTop = () => {
  return (
    <div>

        <a href='#price-list' className="landing-nav-top" id="book-now">

            <div>Get 25% Off On All Services</div>
            
            <span>(Limited Period Offer*)</span>
            
        </a>
        
    </div>
  )
}

export default LandingNavTop