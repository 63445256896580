import React from "react";

import del from "../../images/delhi.jpg";
import noi from "../../images/noida.jpg";
import gur from "../../images/gurgaon.jpg";
import far from "../../images/Faridabad.jpg";
import ghaz from "../../images/Ghaziabad.jpg";


const LandingOurLocation = () => {
  return (
    <div>

      <div className="area-location-location-main">
        {/* ---------------- lp-locations ---------------- */}
        <div className="landing-page-location">
          <div className="landing-page-location-heading">
            We Provide Best <span> Service</span> at <span>Home</span> by{" "}
            <span>Experts</span>
          </div>
     

          <div className="landing-page-location-locations">
            {/* ---------- location 1---------- */}
            <div
              title="Doorstep bike service at home in Delhi"
              to="/bike/delhi"
              className="landing-page-location-locations-col landing-page-location-locations-col-1 "
            >
              <div className="landing-page-location-locations-col-icon">
                <img title="Two wheeler bike service at home in Delhi" src={del} alt="location icon" />
              </div>
              <div className="landing-page-location-locations-col-text">
                Bike Service <br />
                in <span>Delhi</span>
              </div>
            </div>

            {/* ---------- location 2---------- */}
            <div
              title="Doorstep bike service at home in Noida"
              to="/bike/noida"
              className="landing-page-location-locations-col"
            >
              <div className="landing-page-location-locations-col-icon">
                <img title="Two wheeler bike service at home in Noida" src={noi} alt="location icon" />
              </div>
              <div className="landing-page-location-locations-col-text">
                Bike Service <br />
                in <span>Noida</span>
              </div>
            </div>

            {/* ---------- location 3---------- */}
            <div
              title="Doorstep bike service at home in Greater Noida"
              to="/bike/greater-noida"
              className="landing-page-location-locations-col"
            >
              <div className="landing-page-location-locations-col-icon">
                <img title="Two wheeler bike service at home in Greater Noida" src={noi} alt="location icon" />
              </div>
              <div className="landing-page-location-locations-col-text">
                Bike Service in
                <br />
                <span>Greater Noida</span>
              </div>
            </div>

            {/* ---------- location 4---------- */}
            <div
              title="Doorstep bike service at home in Gurugram"
              to="/bike/gurugram"
              className="landing-page-location-locations-col"
            >
              <div className="landing-page-location-locations-col-icon">
                <img title="Two wheeler bike service at home in Gurugram" src={gur} alt="location icon" />
              </div>
              <div className="landing-page-location-locations-col-text">
                Bike Service <br />
                in <span>Gurugram</span>
              </div>
            </div>

            {/* ---------- location 5---------- */}
            <div
              title="Doorstep bike service at home in Faridabad"
              to="/bike/faridabad"
              className="landing-page-location-locations-col"
            >
              <div className="landing-page-location-locations-col-icon">
                <img title="Two wheeler bike service at home in Faridabad" src={far} alt="location icon" />
              </div>
              <div className="landing-page-location-locations-col-text">
                Bike Service <br />
                in <span>Faridabad</span>
              </div>
            </div>

            {/* ---------- location 6---------- */}
            <div
              title="Doorstep bike service at home in Ghaziabad"
              to="/bike/ghaziabad"
              className="landing-page-location-locations-col"
            >
              <div className="landing-page-location-locations-col-icon">
                <img title="Two wheeler bike service at home in Ghaziabad" src={ghaz} alt="location icon" />
              </div>
              <div className="landing-page-location-locations-col-text">
                Bike Service <br />
                in <span>Ghaziabad</span>
              </div>
            </div>


            
            
          </div>
        </div>

        <div className="landing-page-button">

<a href="#book-form">Book Now</a>

</div>

        {/* ----------------------------------------------------------- */}
      </div>
      
    </div>
  );
};

export default LandingOurLocation;
