import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const RedirectionPage = ({navigateTo}) => {

    const navigate = useNavigate();

    const handleRedirect = () => {
        if(navigateTo){
            navigate(navigateTo); 
        }else{
            navigate("/"); 
        }
    };
    
    useEffect( () => {
        handleRedirect()
    }, [])
    
    
  return (
    <></>
  )
}

export default RedirectionPage