import React, { useContext, useState } from "react";

import warrantyIcon from "../../images/icon/warranty.svg";
import expertsIcon from "../../images/icon/experts.svg";
import bestcustomerIcon from "../../images/icon/bestcustomer.svg";
import LoadingComponent from "../user/LoadingComponent";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";

const NumberForm = ({ heading, text}) => {
  const pathname = window.location.pathname;

  const [loading, setLoading] = useState(false);

 
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  // form submit  -----------------------------------------------------------------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------

  const pageFullUrl = window.location.href;
  console.log(pageFullUrl);

  // router navigate  ================================================================================================================
  const navigate = useNavigate();

  // all useStates  ================================================================================================================

  const [error, setError] = useState(null);

  const [data, setData] = useState({
    formUrl: pageFullUrl,
    formType: heading,
    phone: "",
  });

  const handlePhoneNumber = (e) => {
    const value = e.target.value;
    setError(null)

    if (value.length <= 10) {
      setData({
        ...data,
        phone: value,
      });
    }
  };

  const { createBooking } = useContext(AuthContext);

  const submitForm = async (e) => {
    setLoading(true);
    e.preventDefault();

    if (data.phone.length != 10) {
      setError("enter the valid number");
    } else {

      await createBooking(
        data,
        setError,
        navigate
      );
    }
    setLoading(false);
  };

  return (
    <div>
      {loading ? (
        <div className="data-loading">
          <LoadingComponent />
        </div>
      ) : (
        ""
      )}

      <div id="location-form" className="area-location-header-col-2">
        <div className="area-location-header-col-2-content">
          <div className="area-location-header-col-2-headings">
            <h2 className="area-location-header-col-2-heading-1">
              {/* <div className="text-capitalize">&nbsp;{bike}&nbsp;</div> */}
              <div className="text-capitalize">
                {heading ? heading : "Bike Service at Home"}
              </div>
            </h2>
            <div className="area-location-header-col-2-heading-1-text">
              <b>{text ? text : ""}</b>
            </div>
            {/* <div className="area-location-header-col-2-heading-2">
                     {heading2?heading2:"Now Get Your Bike Service at Home and Save Your Time and Money!"}

                  </div> */}
          </div>

          <div className="area-location-header-col-2-form">
            <form onSubmit={submitForm}>
              <div className="sb-c3-1-col"></div>

              <b>Enter your mobile number</b>

{
  error?
  <div className="area-location-header-col-2-form-error">{error}</div>
 :"" 
}

              <input
                type="number"
                placeholder="Phone Number*"
                required
                onChange={handlePhoneNumber}
                value={data.phone}
              />

              {pathname === "/bikes/delhi-ncr" ? (
                <button>Book Now</button>
              ) : (
                <button>Book Now</button>
              )}
            </form>

            <div className="area-location-header-col-2-end">
              <div className="area-location-header-col-2-end-col">
                <div className="area-location-header-col-2-end-icon">
                  <img src={warrantyIcon} alt="icon" title="icon" />
                </div>
                <div className="area-location-header-col-2-end-text">
                  10 Days Service Warranty
                </div>
              </div>

              <div className="area-location-header-col-2-end-col">
                <div className="area-location-header-col-2-end-icon">
                  <img src={expertsIcon} alt="icon" title="icon" />
                </div>
                <div className="area-location-header-col-2-end-text">
                  Expert <br />
                  Mechanics
                </div>
              </div>

              <div className="area-location-header-col-2-end-col">
                <div className="area-location-header-col-2-end-icon">
                  <img src={bestcustomerIcon} alt="icon" title="icon" />
                </div>
                <div className="area-location-header-col-2-end-text">
                  Best Customer
                  <br /> Support{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NumberForm;
