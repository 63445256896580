import React from "react";

import icon1 from "../../images/icon/icon-1.png"
import icon2 from "../../images/icon/icon-2.png"
import icon3 from "../../images/icon/icon-1.png"


// import icon1 from "../../images/icon/transparency.png.webp"
// // import icon1 from "../../images/icon/Periodic-Service.png"
// import icon2 from "../../images/icon/Doorstep-Bike-Services.png"
// import icon3 from "../../images/icon/Light-Fitments.png"

const LocationPageHowWe = () => {
  return (
    <div>
      <div className="location-how-we">
        <div className="location-how-we-main">
          <div className="location-how-we-heading">Two-Wheeler Service <span>At Home</span> With <span>Garage On Call</span></div>

          
          <div className="location-how-we-content">
            

            
            <div className="location-how-we-col">
              <div className="location-how-we-col-img"><img src={icon1} alt="" /></div>
                <div className="location-how-we-col-content">
              <div className="location-how-we-col-heading">Book Online</div>
              <div className="location-how-we-col-text">Visit the Garage On Call website to schedule a service in under 2 minutes</div>
            </div>
            </div>

            
            <div className="location-how-we-col">
              <div className="location-how-we-col-img"><img src={icon2} alt="" /></div>
              <div className="location-how-we-col-content">
              <div className="location-how-we-col-heading">Expert at Your Doorstep</div>
              <div className="location-how-we-col-text">A skilled, trained mechanic arrives at your chosen time and location</div>
            </div>
            </div>

            
            <div className="location-how-we-col">
              <div className="location-how-we-col-img"><img src={icon3} alt="" /></div>
              <div className="location-how-we-col-content">
              <div className="location-how-we-col-heading">Stress-Free Service</div>
              <div className="location-how-we-col-text">Say goodbye to garage, genuine parts & precise repairs while you relax!</div>
            </div>
            </div>


            
          </div>


        </div>
      </div>
    </div>
  );
};

export default LocationPageHowWe;
