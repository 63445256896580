import React from 'react'
import Navbar from '../../components/user/Navbar'
import Footer from '../../components/user/Footer'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const SiteMapPage = () => {
  return (
    <div>
<Helmet>
        <title>On-Demand Bike Service, Your Trusted Repair Solution</title>
        <meta name="description" content="
        From routine maintenance to emergency fixes, we bring the expertise to your doorstep, ensuring your two-wheeler stays in prime condition, hassle-free.
        " />
      </Helmet>
      
       <div className="nav-fix"><Navbar /></div>

    <div className="sitemap">
    <h1 className="sitemap-header">Sitemap</h1>
    <div className="sitemap-content">
        <Link to="/">❯ &nbsp; <h2>Home</h2></Link>
        <Link to="/about-us">❯ &nbsp; <h2>About Us</h2></Link>
        <Link to="/our-prices">❯ &nbsp; <h2>Our Prices</h2></Link>
        <Link to="/our-services">❯ &nbsp; <h2>Our Services</h2></Link>
        <Link to="/blog">❯ &nbsp; <h2>Blog</h2></Link>
        <Link to="/contact-us">❯ &nbsp; <h2>Contact us</h2></Link>
        <Link title='best-bike-service-near-me' className='sitemap-link' to="/register-as-a-partner">❯ &nbsp; <h2>Register as a partner</h2></Link>
    </div>
        
    </div>


        
        <Footer/>
    </div>
  )
}

export default SiteMapPage;