import React from "react";
import Navbar from "../../components/user/Navbar";

import CategoryComponent from "../../components/user/CategoryComponent";
import PackagePriceComponent from "../../components/user/PackagePriceComponent";
import Footer from "../../components/user/Footer";
import OurBenefitsComponent from "../../components/user/OurBenefitsComponent";
import TrustedBrandsComponents from "../../components/user/TrustedBrandsComponents";
import LocationComponent from "../../components/user/LocationComponent";
import BikeBrandLocationComponent from "../../components/user/BikeBrandLocationComponent";
import BikeServiceCheckList from "../../components/user/BikeServiceCheckList";
import HeaderForm from "../../components/user/HeaderForm";
import PricePageComponent from "../../components/user/price/PricePageComponent";

const LocationBikeModelPage = ({ bike, model, location }) => {
  return (
    <div>
      <div className="dn">
      <div className="nav-fix"> 
        <Navbar />
      </div>


        {/* --------------------------- header start -------------------------- */}

        <HeaderForm bike={bike} model={model} location={location} />

        {/* --------------------------- header end -------------------------- */}

        {/* ----------------------------- general service start -------------------------- */}

        <BikeServiceCheckList bike={bike} model={model} location={location} />

        {/* ----------------------------- general service end -------------------------- */}

        <div className="bg-black">
          <CategoryComponent bike={bike} model={model} location={location} />
        </div>

        {/* <PackagePriceComponent /> */}
        <PricePageComponent/>
        <div className="bg-black">
          <OurBenefitsComponent bike={bike} model={model} location={location} />
        </div>

        <LocationComponent />

        <div className="bg-black">
          <TrustedBrandsComponents />
        </div>

        <BikeBrandLocationComponent />

        <Footer />
      </div>
    </div>
  );
};

export default LocationBikeModelPage;
