import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import LoadingComponent from './LoadingComponent';
// import logo from "../../images/GOC-Logo.png"
import logo from "../../images/logo.png"
import AuthContext from '../../context/AuthContext';

const Footer = () => {


  
  const [loading, setLoading] = useState(false);


 



  
    // -----------------------------------------------------------------------------------------------------------------------------------------------------------
    // form submit  -----------------------------------------------------------------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------------------------------------------------
  
    const pageFullUrl = window.location.href;
    console.log(pageFullUrl);
  
    // router navigate  ================================================================================================================
    const navigate = useNavigate();
  
    // all useStates  ================================================================================================================
  
    const [error, setError] = useState(null);
  
    const [data, setData] = useState({
      formUrl: pageFullUrl,
      formType: "Join Us - Footer",
      phone: "",
    });
  
    const handlePhoneNumber = (e) => {
      const value = e.target.value;
      setError(null)
  
      if (value.length <= 10) {
        setData({
          ...data,
          phone: value,
        });
      }
    };
  
    const { createBooking } = useContext(AuthContext);
  
    const submitForm = async (e) => {
      setLoading(true);
      e.preventDefault();
  
      if (data.phone.length != 10) {
        setError("enter the valid number");
      } else {
  
        await createBooking(
          data,
          setError,
          navigate
        );
      }
      setLoading(false);
    };
  
  
  return (
    <div>

{loading ? (
        <div className="data-loading">
          <LoadingComponent />
        </div>
      ) : (
        ""
      )}
        
        <div>
      {/* <div>Footer</div> */}
      <div>
        {/* <div>Footer</div> */}
        <div className='footer-main'>

          {/* footer Head */}
          <div className='footer-head'>

            {/* <div className='fh-logo'>
              <div className='fh-l-logo'><Link title='Bike Repair Shop' to='/'><span> <i className="fa-solid fa-screwdriver-wrench"></i> Garage</span> On Call</Link></div>
              <div className='fh-l-tagline'>ride with happiness</div>
            </div> */}
            
            <Link to="/" className='footer-logo' title='bike service near me'>
              <img src={logo} alt='logo' title='Garage On Call - Best Doorstep Bike Repare & Service' />
            </Link>

            <div className='fh-address'>
              <div className='fh-address-h'>Corporate Office</div>
              <div className='fh-address-p'>
                {/* 520, 5th Floor, Ocean Complex, P-6, Sector-18, Noida, Near to IDBI Bank, GAUTAM BUDDHA NAGAR - 201301  */}
                3rd Floor, Ocean Complex, 313, Sector 18, Noida, Uttar Pradesh 201301
                </div>
            </div>
          </div>

          {/* footer content */}

          <div className='footer-content'>

            {/* fc-cols */}
            {/* col-1 */}
            <div className='fc-col-1'>
              <div className='fc-c-h'>Comapny</div>
              <div className='fc-c-c f-bfr'>

                <Link title='At home bike service' to="/">Home</Link>
                <Link title='Garage On Call - About'  to="/about-us">About Us</Link>
                <Link title='Garage On Call - Contact'  to="/contact-us">Contact Us</Link>
                <Link title='Book best bike service and repair'  to="/book-bike-service">Book service</Link>
                <Link title='Garage On Call - Privacy Policy' to="/privacy-policy-2">Privacy Policy</Link>
                <Link title='Garage On Call - Term & Condition' to="/disclaimer">Term & Condition</Link>
                {/* <Link to="/other">Other</Link> */}
                {/* <Link>Booking</Link> */}

              </div>
            </div>

            {/* col-2 */}
            <div className='fc-col-2'>
              <div className='fc-c-h'>Contact</div>
              <div className='fc-c-c'>

                <Link title='Garage On Call - Book at home bike service email' target='_blank' to="mailto:query@garageoncall.com"><i className="fa-regular fa-envelope"></i> &ensp; query@garageoncall.com</Link>
                <Link title='Garage On Call - Book at home bike service phone number' target='_blank' to="tel:01204225828"><i className="fa-solid fa-phone-volume"></i> &ensp; 0120 4225828</Link>
                <Link title='Garage On Call - Book at home bike service WhatsApp' target='_blank' to="https://wa.me/+919658124124?text=Hello ! I'm Interested in Doorstep Bike Service. Please contact me."><i className="fa-brands fa-whatsapp"></i> &ensp; +91 9658-124-124</Link>
                <Link title='Garage On Call - Book at home bike service website' target='_blank' to="/"><i className="fa-solid fa-globe"></i> &ensp; https://garageoncall.com</Link>

              </div>

            </div>

            {/* col-3 */}
            <div className='fc-col-3'>
              <div className='fc-c-h'>Social Media</div>
              <div className='fc-c-c f-bfr'>

                <Link title='Garage On Call - Best Doorstep Bike Service FaceBook Profile' to="https://www.facebook.com/garageoncallofficial/" target='_blank'><i className="fa-brands fa-facebook-f"></i> &ensp; FaceBook</Link>
                <Link title='Garage On Call - Best Doorstep Bike Service Twitter Profile' to="https://twitter.com/i/flow/login?redirect_after_login=%2FGarageoncall" target='_blank'><i className="fa-brands fa-x-twitter"></i> &ensp; Twitter</Link>
                <Link title='Garage On Call - Best Doorstep Bike Service Instagram Profile' to="https://www.instagram.com/garageoncallofficial/" target='_blank'><i className="fa-brands fa-instagram"></i> &ensp; Instagram</Link>
                <Link title='Garage On Call - Best Doorstep Bike Service Linkedin Profile' to="https://www.linkedin.com/company/garage-on-call/" target='_blank'><i className="fa-brands fa-linkedin-in"></i> &ensp; Linkedin</Link>
                <Link title='Garage On Call - Best Doorstep Bike Service YouTube Profile' to="https://www.youtube.com/@GarageOnCall" target='_blank'><i className="fa-brands fa-youtube"></i> &ensp; YouTube</Link>

              </div>
            </div>

            {/* col-4 */}
            <div className='fc-col-4'>
              <div className='fc-c-h'>Available In</div>
              <div className='fc-c-c f-bfr'>

                <Link title='Best Bike Service in Delhi' to="/bike/delhi">&ensp;Delhi</Link>
                <Link title='Best Bike Service in Noida ' to="/bike/noida">&ensp;Noida </Link>
                <Link title='Best Bike Service in Greater Noida' to="/bike/greater-noida">&ensp;Greater Noida</Link>
                <Link title='Best Bike Service in Gurugram' to="/bike/gurugram">&ensp;Gurugram</Link>
                <Link title='Best Bike Service in Ghaziabad' to="/bike/ghaziabad">&ensp;Ghaziabad</Link>
                <Link title='Best Bike Service in Faridabad' to="/bike/faridabad">&ensp;Faridabad</Link>
                {/* <Link title='Best Bike Service in Chandigarh' to="/bike/chandigarh">&ensp;Chandigarh</Link> */}

              </div>
            </div>


            {/* col-5 */}
            <div className='fc-col-5'>
              <div className='fc-c-h'>Join Us</div>
              <div className='fc-c-c'>
                <div className='fc-c-text'>Join us to get the best doorstep <br />bike service at your location by experts.</div>
                {
  error?
  <div className="area-location-header-col-2-form-error">{error}</div>
 :"" 
}

                <form className='fc-c-form'  onSubmit={submitForm}>
                  <input
                type="number"
                placeholder="Phone Number*"
                required
                onChange={handlePhoneNumber}
                value={data.phone}
              />
                  <button>Join Us</button>
                </form>
              </div>
            </div>

          </div>

        </div>

        <div className='footer-cc'>Copyright <i className="fa-regular fa-copyright"></i> 2019, Garage on Call. All Rights Reserved.</div>

      </div>

    </div>
        
    </div>
  )
}

export default Footer