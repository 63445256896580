import React from 'react'
import img2 from "../../images/bike-service-noida.jpg"

const LandingHowWe = () => {
  return (
    <div>

<div className="landing-how-we">

                    <div className='our-work'>
                        <div className='about-cu-t'>
                            <h2 className='our-work-txt-heading'>How <span>Garage On Call</span> Works?</h2>
                            <p className='our-work-txt-text'>
                            Welcome to Garage On Call, where we redefine the two-wheeler service experience by bringing our expertise to your doorstep. Our streamlined process ensures a hassle-free and efficient service, saving you time and effort. Here's how Garage On Call works
                            </p>
                            <div className='our-work-txt-points'>
                                <div className=''>Book a Service</div>
                                <div className=''>Technician Dispatch</div>
                                <div className=''>Service On The Spot</div>
                                <div className=''>Transparent Communication</div>
                                <div className=''>Quality Assurance</div>
                                <div className=''>Payment and Feedback</div>
                            </div>
                        </div>
                        <div className='our-work-img'><img src={img2} title='Bike service at home' alt='bike-service-noida' /></div>
                    </div>
        
                    <div id="testimonials" className="landing-page-button"><a href="#book-form">Book Now</a></div>

    </div>
        
        
    </div>
  )
}

export default LandingHowWe