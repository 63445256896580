import React from "react";
import Navbar from "../../components/user/Navbar";
import NoidaPageHeader from "../../components/locationPage/NoidaPageHeader";
import { Helmet } from "react-helmet";
import BikeServiceCheckList from "../../components/user/BikeServiceCheckList";
import CategoryComponent from "../../components/user/CategoryComponent";
import PricePageComponent from "../../components/user/price/PricePageComponent";
import OurBenefitsComponent from "../../components/user/OurBenefitsComponent";
import LocationComponent from "../../components/user/LocationComponent";
import TrustedBrandsComponents from "../../components/user/TrustedBrandsComponents";
import BikeBrandLocationComponent from "../../components/user/BikeBrandLocationComponent";
import Footer from "../../components/user/Footer";
import NoidaPageFaq from "../../components/locationPage/NoidaPageFaq";
import LocationPageHowWe from "../../components/locationPage/LocationPageHowWe";

const NoidaLocationPage = () => {
  const schema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "Garage On Call",
    image: "https://garageoncall.com/logo.png",
    description:
      "Professional doorstep bike service in Noida & Greater Noida. Certified mechanics for brake repairs, battery swaps, and full maintenance. Free pickup/drop, genuine parts, and same-day service.",
    telephone: "+91-9658124124",
    url: "https://garageoncall.com/bikes/noida",
    priceRange: "₹299",
    address: {
      "@type": "PostalAddress",
      addressLocality: "Noida",
      addressRegion: "Uttar Pradesh",
      postalCode: "201301",
      addressCountry: "India",
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: "28.5355",
      longitude: "77.3910",
    },
    areaServed: {
      "@type": "Place",
      name: "Noida & Greater Noida",
      description: "Sectors 62, 137, 168, and surrounding areas.",
    },
    hasOfferCatalog: {
      "@type": "OfferCatalog",
      name: "Bike Services",
      itemListElement: [
        {
          "@type": "Offer",
          itemOffered: {
            "@type": "Service",
            name: "Doorstep Bike Repair",
            description:
              "Certified mechanics for brake adjustments, gear fixes, and battery replacements at home.",
          },
        },
        {
          "@type": "Offer",
          itemOffered: {
            "@type": "Service",
            name: "Full Bike Servicing",
            description:
              "Oil changes, engine tuning, and chain adjustments with genuine parts.",
          },
        },
      ],
    },
    sameAs: [
      "https://www.facebook.com/garageoncall",
      "https://www.instagram.com/garageoncall",
    ],
    openingHoursSpecification: {
      "@type": "OpeningHoursSpecification",
      dayOfWeek: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      opens: "08:00",
      closes: "22:00",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "4.9",
      bestRating: "5",
      ratingCount: "150",
    },
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>

        <meta
          name="keywords"
          content="bike garage near me, garage near me for bike, home bike service, bike mechanic near me, bike service at home, bike service near me, bike repair at home, bike service at doorstep, 2 wheeler service at home, scooty service at home, bike repair at home Noida, doorstep bike repair, book bike service"
        />

        <title>Garage on call | Bike Service and Repair at Home in Noida</title>
        <meta
          name="description"
          content="Need a bike mechanic near you? Contact Garage On Call for doorstep bike service, repairs, and free home pickup in Noida. Call 9658124124 to learn more."
        />
      </Helmet>

      <div className="noida-location-page">
        <div className="nav-fix">
          <Navbar />
        </div>

        <NoidaPageHeader />

        <BikeServiceCheckList
          location="noida"
          subheading="Same-Day Bike Repair at Home in Noida with 100% Genuine Parts and a 30-Day Warranty. Local Bike Repair Experts in Noida — No Travel, No Wait, Just Quality Service."
        />

        <div className="bg-black">
          <CategoryComponent subheading="Affordable Two-Wheeler Repairs in Noida – Free Pickup, Transparent Pricing. Book Bike Repair at Home in Noida Today – Save Time, Money, and Hassle." />
        </div>

        <PricePageComponent subheading="Affordable Bike Service at Home in Noida – Certified Mechanics, Same-Day Service. Need Bike Repairs in Noida? Get ₹299 Doorstep Service in Noida with No Hidden Charges." />

        <div className="bg-black">
          <OurBenefitsComponent
            location="Noida"
            subheading="Is Your Bike Broken in Noida? Need Urgent Repairs? Call Now at +91 9658124124 for Home Repairs in Noida starting at ₹299 – Same-Day Fix Guaranteed in Noida."
          />
        </div>

        <LocationComponent />

        <div className="bg-black">
          <TrustedBrandsComponents />
        </div>

        <BikeBrandLocationComponent />

        <LocationPageHowWe />
        <NoidaPageFaq />


        

        <Footer />
      </div>
    </div>
  );
};

export default NoidaLocationPage;
