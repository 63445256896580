import React, { createContext } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // links ============================================================================================

  // const link = "http://localhost:7001";
  const link = "https://server.garageoncall.com";

  // create booking ============================================================================================

  const createBooking = async (data, setError, navigate) => {
    try {
      const response = await fetch(`${link}/bookings`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3Mzc5ODEzMzl9.6TUusoEUakeLbVVZ3NlPQJoSH1sGoSzsS2hFwS2gzPI",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        navigate("/thanks");
      } else {
        const res_data = await response.json();
        // alert(res_data.message);
        setError(res_data.message);
      }
    } catch (error) {
      // alert("server error");
      setError("server error");
      console.log("server error is :", error);
    }
  };

  // fetch bikes ============================================================================================

  const fetchBikes = async (setData) => {
    try {
      const response = await fetch(`https://server.garageoncall.com/bike`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3Mzc5ODEzMzl9.6TUusoEUakeLbVVZ3NlPQJoSH1sGoSzsS2hFwS2gzPI",
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        setData(responseData.data);
      } else {
        console.log("Error in fetching api");
      }

    } catch (error) {
      console.log("server error is :", error);
    }
  };


  return (
    <AuthContext.Provider
      value={{
        createBooking,
        fetchBikes,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
