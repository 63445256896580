import React from 'react'
import { Helmet } from 'react-helmet'
import Navbar from '../../components/user/Navbar'
import HeaderForm from '../../components/user/HeaderForm'
import BikeServiceCheckList from '../../components/user/BikeServiceCheckList'
import CategoryComponent from '../../components/user/CategoryComponent'
import PricePageComponent from '../../components/user/price/PricePageComponent'
import OurBenefitsComponent from '../../components/user/OurBenefitsComponent'
import LocationComponent from '../../components/user/LocationComponent'
import TrustedBrandsComponents from '../../components/user/TrustedBrandsComponents'
import BikeBrandLocationComponent from '../../components/user/BikeBrandLocationComponent'
import Footer from '../../components/user/Footer'

const DelhiLocationPage = ({ bike, model, location }) => {
  return (
    <div>

      <Helmet>
        <title>Bike Repair Services in Delhi On-Site Mechanic Garage On Call</title>
        <meta
          name="description"
          content="Looking for urgent bike repairs in Delhi? Garage On Call's certified mechanics are available 24/7 to fix engines, brakes, and more on-site. Book now!"
        />
      </Helmet>



      <div className="">
      <div className="nav-fix"> 
        <Navbar />
      </div>


        {/* --------------------------- header start -------------------------- */}

        <HeaderForm bike={bike} model={model} location={location} />

        {/* --------------------------- header end -------------------------- */}

        {/* ----------------------------- general service start -------------------------- */}

        <BikeServiceCheckList bike={bike} model={model} location={location} />

        {/* ----------------------------- general service end -------------------------- */}

        <div className="bg-black">
          <CategoryComponent bike={bike} model={model} location={location} />
        </div>

        {/* <PackagePriceComponent /> */}
        <PricePageComponent/>
        <div className="bg-black">
          <OurBenefitsComponent bike={bike} model={model} location={location} />
        </div>

        <LocationComponent />

        <div className="bg-black">
          <TrustedBrandsComponents />
        </div>

        <BikeBrandLocationComponent />

        <Footer />
      </div>


      
      
    </div>
  )
}

export default DelhiLocationPage